html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* @keyframes fadeInGradient {
  from {
    background: white;
  }
  to {
    background: radial-gradient(circle, #fff 10%, #fff 100%);
  }
} */

.graph-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg {
  width: 100%;
  height: 100%;
}

.links line {
  stroke: #d1d1d1;
  stroke-opacity: 0.6;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
  fill: white;
  cursor: pointer;
}

.nodes text {
  cursor: pointer;
}

.nodes text {
  font-size: 24px;
  text-anchor: middle;
}

#inlineFrameExample {
  height: 100%;
  width: 100%;
}

#myModal {
  width: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

.article-link {
  position: fixed;
  bottom: 20px;
  height: 50px;
  padding: 15px;
  width: calc(100% - 60px);
  left: 0;
  right: 0;
  margin: auto;
  /* border: 1px solid gray; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  background-color: white;
  cursor: pointer;
}

.article-link:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  bottom: 20px;
}

.article-link-text {
  font-size: 36px;
}

.article-link-icon {
  font-size: 36px;
  margin-right: 10px;
}
